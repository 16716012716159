html, body, #root {
    height: 100%;
    background-image: url("screen_2.svg");
    background-size: cover;
  }


  .modal {
    position: fixed;
    z-index:9999;
    top :0;
    left:0;
    right:0;
    bottom:0;
    background: #464b5e;
    padding: 3.2rem;
    text-align: center;
}